import React from 'react'
import Layout from '../components/layouts/page'

const About = () => {
	return (
    <div id="page" className='full-height'>
      <Layout>
        <section className='py-5 bg-light'>
          <div>
            <div className='container-xxl pt-5'>
              <div className='d-flex justify-content-center'>
                <div className='col-md-8'>
                  <h1 className='display-1 fw-medium mb-4'>Sorry, the page you were looking for does not exist.</h1>
                  <p className='fs-5 mb-4'><a href='/'>Go to our home page</a> or <a href="/contact">or contact us for assistance</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default About